<template>
    <v-sheet class="my-8 pa-0" max-width="991" >
        <v-item-group>
            <v-row>
                <v-col v-for="(item, index) in imagens.tags" :key="index" cols="12" md="6">
                    <v-row>
                        <v-col cols="12" md="2" class="text-center"><img :src="item.imagem" height="60" class="ma-auto"/></v-col>
                        <v-col cols="12" md="10" class="pa-5" v-html="item.conteudo"></v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-item-group>
    </v-sheet>
</template>

<script>
    export default {
        props: ["imagens"],
        data() {
            return {
                selected: 0
            }
        }
    }
</script>
